import { createContext, useContext } from "react";

export type EnvContextType = {
  REACT_APP_VERSION?: string;
  NODE_ENV?: string;
  TAMARACK_API_URL?: string;
  TEST_USERNAME?: string;
  TEST_PASSWORD?: string;
  TEST_TENANT?: string;
};

const EnvContext = createContext<EnvContextType>({});

export const EnvProvider = EnvContext.Provider;

export const useEnv = () => {
  const env = useContext(EnvContext);
  const isDevelopment = env.NODE_ENV === "development";
  const connectedToLocalApi = env.TAMARACK_API_URL?.includes("localhost");

  return {
    ...env,
    isDevelopment,
    connectedToLocalApi,
    discourageWritingToApi: isDevelopment && !connectedToLocalApi,
  };
};
